@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-pilot/src/style/abstract/variables';
@import '../../../../advox-pilot/src/style/abstract/mixins';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-pilot/src/style/abstract/button';

:root {
    --category-heading-color: #{$default-primary-base-color};
    --category-description-color: #{$grey-dark};
}

.CategoryDetails {
    display: flex;
    grid-gap: 0;

    @include mobile {
        margin: 0 16px;
    }

    &-Wrapper {
        width: 100%;
    }

    &-Heading {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        @include desktop {
            margin-bottom: 34px;
        }

        @include mobileAndTablet {
            margin-bottom: 16px;
        }

        h1 {
            letter-spacing: 1px;
            margin: 0;
            color: var(--category-heading-color);
            font-family: $font-primary;

            @include from-tablet {
                font-size: 30px;
                line-height: 42px;
            }

            @include desktop {
                padding-right: 35px;
            }

            @include tablet {
                padding-right: 20px;
            }

            @include mobile {
                font-size: 24px;
                line-height: 34px;
                padding-right: 20px;
            }
        }
    }

    &-Description {
        @include mobile {
            padding: 0;
        }

        p {
            font-size: 15px;
            line-height: 22px;
            color: var(--category-description-color);
        }
    }

    &-Picture {
        max-width: 150px;

        @include mobile {
            max-width: 60px;
        }
    }

    &_isBrand {
        img {
            max-width: 150px;
            margin-right: 20px;
        }
    }
}
