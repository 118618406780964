@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-pilot/src/style/abstract/variables';
@import '../../../../advox-pilot/src/style/abstract/mixins';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-pilot/src/style/abstract/button';

:root {
    --category-configurable-attributes-thumb-color: #{$default-secondary-base-color};
    --category-configurable-attributes-track-color: #{grey5};
}

.CategoryConfigurableAttributes {
    &-FilterSearcher {
        margin-bottom: 30px;
    }

    &-Attributes {
        max-height: 320px;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 5px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: var(--category-configurable-attributes-thumb-color);
        }

        &::-webkit-scrollbar-track {
            background-color: var(--category-configurable-attributes-track-color);
        }
    }
}
