@import '~advox-basic/src/style/abstract/variables';

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../style/abstract/button';

:root {
    --category-sort-color: #{$default-primary-base-color};
    --category-sort-arrow-color: #{$default-primary-base-color};
    --category-sort-border-color: #{$default-primary-base-color};
    --category-sort-option-hover-color: #{$default-primary-base-color};
    --category-sort-border-radius: 100px;
    --category-sort-border-radius-hover: 25px;
    --category-sort-additional-label-color: #{$blue80};
}
