@import '~advox-basic/src/style/abstract/variables';

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../style/abstract/button';

.CategoryTree {
    --category-tree-heading-color: #{$blue80};
    --category-tree-category-color: #{$blue80};
    --category-tree-category-accent-color: #{$default-secondary-base-color};
    --category-tree-back-color: #{$blue30};
    --category-tree-border-color: #{$blue20};
}
