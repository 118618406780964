@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-pilot/src/style/abstract/variables';
@import '../../../../advox-pilot/src/style/abstract/mixins';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-pilot/src/style/abstract/button';

:root {
    --category-configurable-attributes-thumb-color: #{$default-primary-base-color};
    --category-configurable-attributes-track-color: #{$purple10};
}

.CategoryConfigurableAttributes {
    &-FilterSearcher {
        @include desktop {
            margin-top: 20px;
        }
        .Field_type_search {
            width: 100%;
            svg {
                fill: $purple80;
            }
            input {
                --input-padding: 8px 24px;
                background: $white;
                width: 100%;
                border-color: $purple10;
                &::placeholder {
                    color: $purple40;
                    font-size: 13px;
                    line-height: 20px;
                }

                &:focus {
                    border-color: $default-primary-base-color;
                    box-shadow: none;
                    color: $default-primary-base-color;
                }
            }
        }
    }
}
